<template>
  <!-- <div class="contents" style="height:90%">
  <div class="contents"> -->
  <iui-container type="css-flex" theme="bullet">
    <i-row v-if="isPart">
      <i-col>
        <PartView />
      </i-col>
    </i-row>
    <i-row v-if="isConstCmpl">
      <i-col>
        <ConstCmplView />
      </i-col>
    </i-row>
  </iui-container>
  <!-- <div class="btn_area">
      <button type="button" class="btn_default" @click="onLogin">로그인</button>
    </div>
  </div> -->
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import LinkConfDocStore from './store/LinkConfDoc.js';
import ConfDocPartStore from './part/store/part.js';
import ConfDocConstCmplStore from './constcmpl/store/constcmpl.js';
import PartView from '@/view/confdoc/components/linkconfdoc/PartView.vue';
import ConstCmplView from '@/view/confdoc/components/linkconfdoc/ConstCmplView.vue';

export default {
  name: 'LinkConfDoc',
  components: {
    // eslint-disable-next-line
    PartView,
    // eslint-disable-next-line
    ConstCmplView,
  },
  beforeCreate() {
    this.$store.dispatch('setStoreNm', 'LinkConfDocStore');
    $init(this, LinkConfDocStore);
    this.$store.dispatch('setStoreNm', 'ConfDocPartStore');
    $init(this, ConfDocPartStore);
    this.$store.dispatch('setStoreNm', 'ConfDocConstCmplStore');
    $init(this, ConfDocConstCmplStore);
  },
  data() {
    return {};
  },
  mounted() {
    this.checkAccessDeviceType();
  },
  methods: {
    ...mapMutations('login', ['setPage']),
    ...mapMutations('LinkConfDocStore', {
      setDecParams: 'setDecParams',
    }),
    async checkAccessDeviceType() {
      console.log('###############');
      console.log(window.params);

      let param = {
        encParam: window.params.encParam,
      };

      let response = await axios.post('/urlLink/decAndParseParam', param);
      console.log(response);
      console.log('###############');

      this.setDecParams(response.data);

      console.log('decParams', this.decParams);

      var isIE = /*@cc_on!@*/ false || !!document.documentMode;
      if (isIE) {
        this.$alert('현재 인터넷 익스플로어(IE)로 접속하셨습니다.<br/>크롬(Chrome)브라우저 사용을 권고 드립니다.');
      }

      if (this.isPart) {
        console.log('isPart');
        this.callEvent({name: 'ConfDoc_Part_Search', param: undefined});
      } else if (this.isConstCmpl) {
        console.log('isConstCmpl');
        this.callEvent({name: 'ConfDoc_ConstCmpl_Search', param: undefined});
      }
    },

    onLogin() {
      this.setPage('Login');
    },
  },
  computed: {
    ...mapGetters('LinkConfDocStore', {
      decParams: 'decParams',
    }),
    isPart() {
      return window.params.shortUrlLinkType == 'CD_P';
    },
    isConstCmpl() {
      return window.params.shortUrlLinkType == 'CD_CC';
    },
  },
};
</script>

<style scoped></style>
