<template>
  <div id="app" class="h_100">
    <component v-bind:is="currentView"></component>
    <iui-alert />
    <iui-confirm />
    <iui-loading /><!--2021 lgksja 로딩용-->
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import Home from '@/view/Home.vue';
import LoginManager from '@/view/login/LoginManager.vue';
import LoginCustomer from '@/view/login/LoginCustomer.vue';
import LinkView from '@/view/contract/LinkView.vue';
import LinkProving from '@/view/contract/LinkProving.vue';
import LinkConfDoc from '@/view/confdoc/LinkConfDoc.vue';
import Popup from '@/view/common/popup/Popup.vue';
import WindowPopup from '@/view/common/popup/WindowPopup.vue';
import NetworkErr from '@/view/common/NetworkErr.vue';
import '@/plugins/printExcel.js';
import NewLinkView from '@/view/common/LinkView.vue';

export default {
  name: 'app',
  components: {
    // "Home": Home,
    // "Login": Login,1
  },
  data: function() {
    return {
      currentView: null,
    };
  },
  computed: {
    ...mapGetters('login', {
      isLogin: 'isLogin',
      page: 'isPage',
    }),
  },
  watch: {
    isLogin: function() {
      if (this.isLogin) {
        history.pushState(null, null, '/Home');
        this.currentView = Home;
      }
    },
    page: function() {
      this.doPageChange();
    },
  },
  created: function() {
    setTimeout(() => {
      console.log('###############');
      console.log(this.page);
      console.log(window.params);
      console.log(window.params.shortUrlLinkType);
      console.log(window.params.encParam);
      console.log(window.params.errMsg);
      if (window.params.errMsg !== undefined && window.params.errMsg != '') {
        //this.$alert(window.params.errMsg);
        //모바일로 넘어갈는경우가 있어서 alert으로 변경 20230220
        alert(window.params.errMsg);
      } else {
        if (
          window.params.shortUrlLinkType !== undefined &&
          window.params.shortUrlLinkType != '' &&
          window.params.encParam !== undefined &&
          window.params.encParam != ''
        ) {
          var shortUrlLinkType = window.params.shortUrlLinkType;
          if (
            shortUrlLinkType == 'OC_V' ||
            shortUrlLinkType == 'OD_V' ||
            shortUrlLinkType == 'TC_V' ||
            shortUrlLinkType == 'TCA_V' ||
            shortUrlLinkType == 'EST_V' ||
            shortUrlLinkType == 'CMS_V'  //20230214 cms 서명용
          ) {
            this.setPage('LinkView');
          } else if (
            shortUrlLinkType == 'OC_PC' ||
            shortUrlLinkType == 'OD_PC' ||
            shortUrlLinkType == 'TC_PC' ||
            shortUrlLinkType == 'TCA_PC'
          ) {
            this.setPage('LinkProving');
          } else if (shortUrlLinkType == 'CD_P' || shortUrlLinkType == 'CD_CC') {
            this.setPage('LinkConfDoc');
          } else if (
            shortUrlLinkType == 'NE_V' ||
            shortUrlLinkType == 'MOD_V' ||
            shortUrlLinkType == 'FOD_V' ||
            shortUrlLinkType == 'JSA_V' ||
            shortUrlLinkType == 'NPP_V'
          ) {
            this.setPage('NewLinkView');
          } else if (
            // shortUrlLinkType == 'NE_PC' ||
            // shortUrlLinkType == 'MOD_PC' ||
            // shortUrlLinkType == 'FOD_PC' ||
            // shortUrlLinkType == 'NPP_PC' //20230228 잘못되어있었음
            shortUrlLinkType == 'NE_P' ||
            shortUrlLinkType == 'MOD_P' ||
            shortUrlLinkType == 'FOD_P' ||
            shortUrlLinkType == 'JSA_P' ||
            shortUrlLinkType == 'NPP_P'
          ) {
            this.setPage('NewLinkView');
          }
        }
      }
      console.log(this.page);
      console.log('###############');
      this.doPageChange();
    }, 1);
  },
  mounted() {
    const ibsheetLoader = window.IBSheetLoader;

    let config = {
      registry: [
        {
          name: 'ibsheet',
          baseUrl: '/ext/ibsheet8/v8.0.0.23-DEV.3',
          locales: ['en', 'ko'],
          theme: 'otis',
          plugins: ['excel'],
          license:
            'W2FtSztPKCNwbzcwYjJxbn9SMVxtHyk1f3w5enI2YiNmdERrEihSL2woa3h+bjtyfyt4DWJcdAQ7eDQwcyBsKXJ+fGxGIgd8EwFzdzVsFlIPcCh1IxxhMlZsCikwLT11JjxicDRx',
        },
      ],
    };

    // 개발 라이선스
    if (
      location.hostname !== undefined &&
      location.hostname !== null &&
      (location.hostname.indexOf('econtracttest.') !== -1 ||
        location.hostname.indexOf('localhost') !== -1 ||
        location.hostname.indexOf('127.0.0.1') !== -1)
    ) {
      config.registry[0].license =
        'W2FtSztPKCVzaDY2YjJxbn9NNREsGXRrZWEiOygnaH9maU58BHIRZTU+aGt+LG47JTBtVmAdZUFmZSM+LyZpaGVoNjcPfR9kHD1sansrbzoyM2wuNBInDncLJzx0PiszY2kkNmciCm8PMU1jfypqPTMyey0qbiFEaEIhEy0nb2xjfjQhcyFhIh4/T2QcKXssaCluf3s6N3c1SAkwS1d7cyA7KmE3ZDJiICoZ'; //2023-11-15~2024-11-14
    }

    ibsheetLoader.config(config);
  },
  methods: {
    ...mapMutations('login', ['setPage']),
    doPageChange: function() {
      let isCustomerLogin = false;
      if (
        location.search.indexOf('login=customer') > 0 ||
        location.href.indexOf('econtracttest.otis.co.kr') > 0 ||
        location.href.indexOf('econtract.otis.co.kr') > 0 ||
        location.href.indexOf('localhost') > 0
      ) {
        isCustomerLogin = true;
      }
      if (location.search.indexOf('login=manager') > 0) {
        isCustomerLogin = false;
      }

      if (params && params.isPopup) return;

      switch (this.page) {
        case 'Login':
          this.currentView = isCustomerLogin ? LoginCustomer : LoginManager;
          break;
        case 'Home':
          if (location.search == '?Popup') {
            this.currentView = Popup;
          } else {
            this.currentView = Home;
          }
          break;
        case 'LinkView':
          this.currentView = LinkView;
          break;
        case 'LinkProving':
          this.currentView = LinkProving;
          break;
        case 'LinkConfDoc':
          this.currentView = LinkConfDoc;
          break;
        case 'Popup':
          this.currentView = Popup;
          break;
        case 'WindowPopup':
          this.currentView = WindowPopup;
          break;
        case 'NetworkErr':
          this.currentView = NetworkErr;
          break;
        case 'NewLinkView':
          this.currentView = NewLinkView;
          break;
        default:
          this.currentView = isCustomerLogin ? LoginCustomer : LoginManager;
      }
    },
  },
};
</script>

<style></style>
